
import $ from "jquery";


function init() {


}

$(function () {
    const target = window.location.hash;
    if (target) {
        jumpTo(target);
    }
    $('a[href^="#"]').not('.submenu-trigger').on('click', function (e) {
        e.preventDefault();
        jumpTo($(this).attr('href'));
    });
});

function jumpTo(target) {
    const offset = $(target).offset().top - $('header').outerHeight() - 80;
    if (($(target).length)) {
        window.location.hash = target.replace('#', '');
        $(window).scrollTop(offset);
    }
}

export default init();