import $ from "jquery";
import Handlebars from "handlebars";
import _ from "lodash";
var debugging = false, // true | false
    env = "prod"; // staging | prod

var TFK_DATA = {
    geoColors: [
        "#c0c0c0",
        "#fffe7f",
        "#f29d39",
        "#41962a",
        "#ffffff",
        "#ff0000",
        "#00b0f0",
    ],

    /*Green: #41962a
    Orange: #f29d39
    Yellow: #fffe7f
    Gray: #c0c0c0
    Red: #ff0000*/

    dataFile: function () {
        // if (document.location.href.match(/usmap/)) {
        //     return "js/output.json"
        // }
        // else return "../js/output.json"
        return "../../assets/json/state_report.json";
    },
    states: [],
};

var TFK_UTILS = {
    /**
     * METHOD: colorLookup
     * PURPOSE: returns the index number of the color string passed
     * @param str
     * @returns {number}
     */
    colorLookup: function (str) {
        return _.indexOf(TFK_DATA.geoColors, str);
    },
    /**
     * METHOD: makeTemplate
     * PURPOSE: Returns a compiled HTML snippet scoped for the passed in data object
     * @param obj
     * @returns {*}
     */
    makeTemplate: function (obj, id) {
        var source = $("#" + id).html(),
            template = Handlebars.compile(source),
            html = template(obj);
        return html;
    },
    /**
     * METHOD: parseStates
     * PURPOSE: Returns a formatted list of state data in the GoogleCharts GeoChart format
     * @returns {*[]}
     */
    parseStatesForGeoChart: function () {
        //console.log("parseStatesForGeoChart loaded", TFK_DATA.states);

        var service = this,
            data = TFK_DATA.states;

        // init new data array; add the column definitions
        var DataArray = [
            ["State", "Value", { role: "tooltip", p: { html: true } }],
        ];

        // loop through the state data and create the proper format for Google Chart
        for (var i in data) {
            var rec = data[i];

            //if (debugging) console.log(rec);

            var colorIdx = service.colorLookup(rec.data.stateColor),
                toolTipHtml = service.makeTemplate(rec, "tooltip-template");

            DataArray.push([rec.name, colorIdx, toolTipHtml]);
        }

        if (debugging) console.log(DataArray);
        return DataArray;
    },
    parseStatesForChart1: function (state) {
        var service = this,
            data = _.findWhere(TFK_DATA.states, { name: state }),
            fields = [
                "stateRevenue",
                "cdcRecommend",
                "stateSpending",
                "estimatedMarketing",
            ];

        // init new data array; add the column definitions
        var DataArray = [
            [
                " ",
                "$",
                { role: "style" },
                { role: "tooltip", p: { html: true } },
                { type: "string", role: "annotation" },
            ],
        ];

        if (debugging) console.log("parseStatesForChart1", data);

        for (var i in fields) {
            var fieldName = fields[i],
                fieldData = data.data[fieldName],
                toolTipHtml = service.makeTemplate(
                    fieldData,
                    "chart1-tooltip-template"
                ),
                annotation = TFK_UTILS.moneyFormat(
                    fieldData.visibleAmount
                        ? fieldData.visibleAmount
                        : fieldData.amount
                );

            DataArray.push([
                fieldData.text,
                fieldData.amount,
                fieldData.color,
                toolTipHtml,
                annotation,
            ]);
        }

        if (debugging) console.log(DataArray);
        return DataArray;
    },
    parseStatesForChart2: function (state) {
        var service = this,
            data = _.findWhere(TFK_DATA.states, { name: state }),
            fields = ["preventionSpending"];

        // init new data array; add the column definitions
        var DataArray = [
            [
                "FY",
                "$",
                { role: "style" },
                { role: "tooltip", p: { html: true } },
                //,{type: 'string', role: 'annotation'}
            ],
        ];

        if (debugging) console.log(data);

        var fieldDataList = data.data.preventionSpending;

        for (var i in fieldDataList) {
            var rec = fieldDataList[i];

            if (rec.amount < 50000) rec.amount = 0;
            else if (rec.amount >= 50000 && rec.amount < 100000)
                rec.amount = 100000;
            else {
                rec.amount = Math.round(rec.amount / 100000) * 100000;
            }

            var toolTipHtml = service.makeTemplate(
                rec,
                "chart2-tooltip-template"
            );
            //,annotation  = (i == fieldDataList.length - 1) ? "$" + TFK_UTILS.moneyFormat(rec.amount, "long") : "";

            console.log("parseStatesForChart2", rec);

            DataArray.push([
                rec.name,
                rec.amount,
                rec.color,
                toolTipHtml,
                //,annotation
            ]);
        }

        if (debugging) console.log(DataArray);
        return DataArray;
    },

    /**
     * METHOD: moneyFormat
     * PURPOSE: formats money into a string with K/M/B
     * @param labelValue
     * @returns {string}
     */
    moneyFormat: function (labelValue, option, opt2) {
        if (debugging)
            console.log("moneyFormat: ", {
                labelValue: labelValue,
                option: option,
            });

        var calc = "";
        var postfix = "",
            numVal = 0;

        var filter = function (num) {
            return num.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        };

        if (labelValue < 0) return "N/A*";

        if (option == "axis") {
            if (labelValue > 999999) {
                numVal = (labelValue / 1000000).toFixed(0);
                postfix = " million";
            } else {
                numVal = labelValue;
            }

            calc = "$" + numVal + postfix;
        } else if (option == "axis_chart2") {
            numVal = filter(labelValue / 1000000);
            postfix = " million";

            //console.log("filtered", numVal);

            calc = "$" + numVal + postfix;
        } else if (option == "chart2") {
            if (labelValue > 999999) {
                numVal = (labelValue / 1000000).toFixed(1);
                postfix = " million";
            } else {
                numVal = filter((labelValue / 1000000).toFixed(1));
                postfix = " million";
            }

            if (opt2) calc = numVal;
            else calc = "$" + numVal + postfix;

            console.log("chart2 calc", calc);
        } else if (option == "val") {
            numVal = (labelValue / 1000000).toFixed(1);
            postfix = "";

            calc = numVal;
        } else {
            numVal = (labelValue / 1000000).toFixed(1);
            postfix = " million";

            calc = "$" + numVal + postfix;
        }

        return calc;
    },
};

var TFK_CHARTS = {
    usmap: function (data_json) {
        if (debugging) console.log("TFK_CHARTS -> US MAP Loaded.");

        // tooltip curency formatter helper
        Handlebars.registerHelper("formatCurrency", function (data) {
            return TFK_UTILS.moneyFormat(data);
        });
        Handlebars.registerHelper("capitalize", function (data) {
            //return data.charAt(0).toUpperCase() + data.slice(1);
            return data.replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        });
        Handlebars.registerHelper("nadefault", function (data) {
            if (data < 0) return "N/A*";
            else return data;
        });

        var data = JSON.parse(data_json);
        // data.shift();
        TFK_DATA.states = data;
        // load the chart
        google.load("visualization", "1", {
            packages: ["geochart"],
            callback: function () {
                if (debugging)
                    console.log("google setOnLoadCallback", TFK_DATA.geoColors);

                var params = {};

                if (location.search) {
                    var parts = location.search.substring(1).split("&");

                    for (var i = 0; i < parts.length; i++) {
                        var nv = parts[i].split("=");
                        if (!nv[0]) continue;
                        params[nv[0]] = nv[1] || true;
                    }
                }

                var data = google.visualization.arrayToDataTable(
                        TFK_UTILS.parseStatesForGeoChart()
                    ),
                    options = {
                        sizeAxis: { minValue: 0, maxValue: 100 },
                        region: "US",
                        resolution: "provinces",
                        tooltip: {
                            isHtml: true,
                            showTitle: false,
                        },
                        colorAxis: {
                            colors: TFK_DATA.geoColors,
                            minValue: 0,
                            maxValue: TFK_DATA.geoColors.length - 1,
                        },
                        legend: "none",
                    },
                    chart = new google.visualization.GeoChart(
                        document.getElementById("regions_div")
                    ),
                    selectHandler = function () {
                        var selectedItem = chart.getSelection()[0];
                        if (selectedItem) {
                            var topping = data.getValue(selectedItem.row, 0);
                            var seo_topping = topping.replace(" ", "-");
                            var seo_topping = seo_topping.toLowerCase();
                            var url =
                                "/what-we-do/us/statereport/" + seo_topping;
                            //var url = "http://www.hirethisdeveloper.com/clients/TFK/statereports";

                            // alert(url);

                            // if (env == "staging") url += "_staging/" + topping.toLowerCase().replace(" ", "") + ".html";
                            // else if (env == "prod") url += "/" + topping.toLowerCase().replace(" ", "");

                            // if (debugging) console.log("URL: ", url);

                            window.parent.location.href = url;
                        }
                    };

                chart.draw(data, options);
                google.visualization.events.addListener(
                    chart,
                    "select",
                    selectHandler
                );

                // header
                if (params.includeHeader) {
                    $("header").html(
                        TFK_UTILS.makeTemplate({}, "header-template")
                    );
                }
                if (params.includeFooter) {
                    $("footer").html(
                        TFK_UTILS.makeTemplate({}, "footer-template")
                    );
                }
            },
        });
    },
    chart1: function () {
        // tooltip curency formatter helper
        Handlebars.registerHelper("formatCurrency", function (data) {
            return TFK_UTILS.moneyFormat(data);
        });
        Handlebars.registerHelper("capitalize", function (data) {
            return data.charAt(0).toUpperCase() + data.slice(1);
        });

        $.ajax({
            url: TFK_DATA.dataFile(),
            dataType: "json",
            method: "GET",
            success: function (data) {
                TFK_DATA.states = data;
                // load the chart
                google.load("visualization", "1", {
                    packages: ["corechart"],
                    callback: function () {
                        if (debugging)
                            console.log(
                                "google setOnLoadCallback",
                                TFK_DATA.geoColors
                            );

                        var params = {};

                        if (location.search) {
                            var parts = location.search.substring(1).split("&");

                            for (var i = 0; i < parts.length; i++) {
                                var nv = parts[i].split("=");
                                if (!nv[0]) continue;
                                params[nv[0]] = nv[1] || true;
                            }
                        }

                        // Now you can get the parameters you want like so:
                        var state = params.state
                            .toLowerCase()
                            .replace("%20", " ");

                        if (debugging)
                            console.log("TFK_CHARTS -> CHART1 Loaded.", state);

                        var d = TFK_UTILS.parseStatesForChart1(state);
                        if (debugging) console.log("parsedarray: ", d);
                        var MARKER = ":::";
                        var data = google.visualization.arrayToDataTable(d),
                            options = {
                                sizeAxis: { minValue: 0, maxValue: 100 },
                                legend: "none",
                                tooltip: { isHtml: true },
                                bar: { groupWidth: "50%" },
                                vAxis: {
                                    format: MARKER + "##",
                                    gridlines: { count: 4 },
                                },
                                annotations: {
                                    alwaysOutside: true,
                                    textStyle: {
                                        fontSize: 14,
                                        bold: true,
                                        color: "#000000",
                                    },
                                },
                            },
                            chart = new google.visualization.ColumnChart(
                                document.getElementById("number_format_chart")
                            );

                        chart.draw(data, options);

                        var svg = $("#number_format_chart svg");
                        var items = svg.find("text");
                        if (debugging) console.log("TEXT", items);
                        _.each(items, function (item) {
                            var content = $(item).text();
                            if (debugging) console.log("content", content);
                            if (content.match(MARKER)) {
                                var val = TFK_UTILS.moneyFormat(
                                    parseFloat(content.replace(MARKER, "")),
                                    "axis"
                                );
                                $(item).text(val);
                            }
                        });
                    },
                });
            },
        });
    },
    chart2: function () {
        // tooltip curency formatter helper
        Handlebars.registerHelper("formatCurrency", function (data) {
            return TFK_UTILS.moneyFormat(data, "chart2");
        });
        Handlebars.registerHelper("capitalize", function (data) {
            if (data) {
                return data.charAt(0).toUpperCase() + data.slice(1);
            } else return data;
        });

        $.ajax({
            url: TFK_DATA.dataFile(),
            dataType: "json",
            method: "GET",
            success: function (data) {
                TFK_DATA.states = data;
                // load the chart
                google.load("visualization", "1", {
                    packages: ["corechart"],
                    callback: function () {
                        if (debugging)
                            console.log(
                                "google setOnLoadCallback",
                                TFK_DATA.geoColors
                            );

                        var params = {};

                        if (location.search) {
                            var parts = location.search.substring(1).split("&");

                            for (var i = 0; i < parts.length; i++) {
                                var nv = parts[i].split("=");
                                if (!nv[0]) continue;
                                params[nv[0]] = nv[1] || true;
                            }
                        }

                        // Now you can get the parameters you want like so:
                        var state = params.state
                            .toLowerCase()
                            .replace("%20", " ");

                        if (debugging)
                            console.log("TFK_CHARTS -> CHART2 Loaded.", state);

                        // load the chart
                        google.load("visualization", "1", {
                            packages: ["corechart"],
                            callback: function () {
                                var d = TFK_UTILS.parseStatesForChart2(state);
                                if (debugging) console.log("parsedarray: ", d);
                                var MARKER = ":::";
                                var data =
                                        google.visualization.arrayToDataTable(
                                            d
                                        ),
                                    options = {
                                        sizeAxis: {
                                            minValue: 0,
                                            maxValue: 100,
                                        },
                                        legend: "none",
                                        tooltip: { isHtml: true },
                                        bar: { groupWidth: "50%" },
                                        vAxis: {
                                            format: MARKER + "##",
                                            gridlines: { count: 4 },
                                        },
                                    },
                                    chart =
                                        new google.visualization.ColumnChart(
                                            document.getElementById(
                                                "number_format_chart"
                                            )
                                        );

                                chart.draw(data, options);

                                var svg = $("#number_format_chart svg");
                                var items = svg.find("text");
                                if (debugging) console.log("TEXT", items);
                                _.each(items, function (item) {
                                    var content = $(item).text();
                                    if (debugging)
                                        console.log("content", content);
                                    if (content.match(MARKER)) {
                                        var val = TFK_UTILS.moneyFormat(
                                            parseFloat(
                                                content.replace(MARKER, "")
                                            ),
                                            "axis_chart2"
                                        );
                                        $(item).text(val);
                                    }
                                });

                                // header
                                if (params.includeHeader) {
                                    $("header").html(
                                        TFK_UTILS.makeTemplate(
                                            _.findWhere(TFK_DATA.states, {
                                                name: state,
                                            }),
                                            "header-template"
                                        )
                                    );
                                }
                                if (params.includeFooter) {
                                    $("footer").html(
                                        TFK_UTILS.makeTemplate(
                                            _.findWhere(TFK_DATA.states, {
                                                name: state,
                                            }),
                                            "footer-template"
                                        )
                                    );
                                }
                            },
                        });
                    },
                });
            },
        });
    },
};

let json_data = [
    {
        name: "alabama",
        data: {
            rank: 45,
            cdcRecommend: { amount: 55900000 },
            stateSpending: { amountTXT: "$1.7 million" },
            estimatedMarketing: { amount: 202200000 },
            percentCDCSpending: 3.1000000000000001,
            stateColor: "silver",
        },
    },
    {
        name: "alaska",
        data: {
            rank: 6,
            cdcRecommend: { amount: 10200000 },
            stateSpending: { amountTXT: "$6.5 million" },
            estimatedMarketing: { amount: 17500000 },
            percentCDCSpending: 63.5,
            stateColor: "#41962a",
        },
    },
    {
        name: "arizona",
        data: {
            rank: 19,
            cdcRecommend: { amount: 64400000.000000007 },
            stateSpending: { amountTXT: "$17.7 million" },
            estimatedMarketing: { amount: 123700000 },
            percentCDCSpending: 27.5,
            stateColor: "#f29d39",
        },
    },
    {
        name: "arkansas",
        data: {
            rank: 21,
            cdcRecommend: { amount: 36700000 },
            stateSpending: { amountTXT: "$9 million" },
            estimatedMarketing: { amount: 116600000 },
            percentCDCSpending: 24.5,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "california",
        data: {
            rank: 8,
            cdcRecommend: { amount: 347900000 },
            stateSpending: { amountTXT: "$199.5 million" },
            estimatedMarketing: { amount: 485000000 },
            percentCDCSpending: 57.299999999999997,
            stateColor: "#41962a",
        },
    },
    {
        name: "colorado",
        data: {
            rank: 10,
            cdcRecommend: { amount: 52900000 },
            stateSpending: { amountTXT: "$24.7 million" },
            estimatedMarketing: { amount: 136700000 },
            percentCDCSpending: 46.700000000000003,
            stateColor: "#f29d39",
        },
    },
    {
        name: "connecticut",
        data: {
            rank: 12,
            cdcRecommend: { amount: 32000000 },
            stateSpending: { amountTXT: "$13.6 million" },
            estimatedMarketing: { amount: 60300000 },
            percentCDCSpending: 42.600000000000001,
            stateColor: "#f29d39",
        },
    },
    {
        name: "delaware",
        data: {
            rank: 5,
            cdcRecommend: { amount: 13000000 },
            stateSpending: { amountTXT: "$9.7 million" },
            estimatedMarketing: { amount: 41300000 },
            percentCDCSpending: 74.5,
            stateColor: "#41962a",
        },
    },
    {
        name: "florida",
        data: {
            rank: 13,
            cdcRecommend: { amount: 194200000 },
            stateSpending: { amountTXT: "$77.7 million" },
            estimatedMarketing: { amount: 614300000 },
            percentCDCSpending: 40,
            stateColor: "#f29d39",
        },
    },
    {
        name: "georgia",
        data: {
            rank: 48,
            cdcRecommend: { amount: 106000000 },
            stateSpending: { amountTXT: "$2.1 million" },
            estimatedMarketing: { amount: 360900000 },
            percentCDCSpending: 2,
            stateColor: "silver",
        },
    },
    {
        name: "hawaii",
        data: {
            rank: 9,
            cdcRecommend: { amount: 13700000 },
            stateSpending: { amountTXT: "$7.6 million" },
            estimatedMarketing: { amount: 26000000 },
            percentCDCSpending: 55.299999999999997,
            stateColor: "#41962a",
        },
    },
    {
        name: "idaho",
        data: {
            rank: 18,
            cdcRecommend: { amount: 15600000 },
            stateSpending: { amountTXT: "$4.4 million" },
            estimatedMarketing: { amount: 49500000 },
            percentCDCSpending: 28.5,
            stateColor: "#f29d39",
        },
    },
    {
        name: "illinois",
        data: {
            rank: 39,
            cdcRecommend: { amount: 136700000 },
            stateSpending: { amountTXT: "$10.1 million" },
            estimatedMarketing: { amount: 220700000 },
            percentCDCSpending: 7.4000000000000004,
            stateColor: "silver",
        },
    },
    {
        name: "indiana",
        data: {
            rank: 34,
            cdcRecommend: { amount: 73500000 },
            stateSpending: { amountTXT: "$7.5 million" },
            estimatedMarketing: { amount: 308000000 },
            percentCDCSpending: 10.199999999999999,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "iowa",
        data: {
            rank: 26,
            cdcRecommend: { amount: 30100000 },
            stateSpending: { amountTXT: "$4.3 million" },
            estimatedMarketing: { amount: 106000000 },
            percentCDCSpending: 14.199999999999999,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "kansas",
        data: {
            rank: 42,
            cdcRecommend: { amount: 27900000 },
            stateSpending: { amountTXT: "$1 million" },
            estimatedMarketing: { amount: 74200000 },
            percentCDCSpending: 3.6000000000000001,
            stateColor: "silver",
        },
    },
    {
        name: "kentucky",
        data: {
            rank: 43,
            cdcRecommend: { amount: 56400000 },
            stateSpending: { amountTXT: "$2 million" },
            estimatedMarketing: { amount: 267100000.00000003 },
            percentCDCSpending: 3.5,
            stateColor: "silver",
        },
    },
    {
        name: "louisiana",
        data: {
            rank: 38,
            cdcRecommend: { amount: 59600000 },
            stateSpending: { amountTXT: "$5.1 million" },
            estimatedMarketing: { amount: 186700000 },
            percentCDCSpending: 8.5999999999999996,
            stateColor: "silver",
        },
    },
    {
        name: "maine",
        data: {
            rank: 2,
            cdcRecommend: { amount: 15900000 },
            stateSpending: { amountTXT: "$15.9 million" },
            estimatedMarketing: { amount: 49700000 },
            percentCDCSpending: 100,
            stateColor: "#00b0f0",
        },
    },
    {
        name: "maryland",
        data: {
            rank: 11,
            cdcRecommend: { amount: 48000000 },
            stateSpending: { amountTXT: "$20.6 million" },
            estimatedMarketing: { amount: 126100000 },
            percentCDCSpending: 42.899999999999999,
            stateColor: "#f29d39",
        },
    },
    {
        name: "massachusetts",
        data: {
            rank: 36,
            cdcRecommend: { amount: 66900000.000000007 },
            stateSpending: { amountTXT: "$6.1 million" },
            estimatedMarketing: { amount: 110700000 },
            percentCDCSpending: 9.1999999999999993,
            stateColor: "silver",
        },
    },
    {
        name: "michigan",
        data: {
            rank: 49,
            cdcRecommend: { amount: 110600000 },
            stateSpending: { amountTXT: "$1.8 million" },
            estimatedMarketing: { amount: 323800000 },
            percentCDCSpending: 1.7,
            stateColor: "silver",
        },
    },
    {
        name: "minnesota",
        data: {
            rank: 23,
            cdcRecommend: { amount: 52900000 },
            stateSpending: { amountTXT: "$11.7 million" },
            estimatedMarketing: { amount: 112200000 },
            percentCDCSpending: 22.100000000000001,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "mississippi",
        data: {
            rank: 22,
            cdcRecommend: { amount: 36500000 },
            stateSpending: { amountTXT: "$8.7 million" },
            estimatedMarketing: { amount: 131800000.00000001 },
            percentCDCSpending: 23.800000000000001,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "missouri",
        data: {
            rank: 41,
            cdcRecommend: { amount: 72900000 },
            stateSpending: { amountTXT: "$2.9 million" },
            estimatedMarketing: { amount: 359600000 },
            percentCDCSpending: 3.8999999999999999,
            stateColor: "silver",
        },
    },
    {
        name: "montana",
        data: {
            rank: 15,
            cdcRecommend: { amount: 14600000 },
            stateSpending: { amountTXT: "$4.9 million" },
            estimatedMarketing: { amount: 30800000 },
            percentCDCSpending: 33.200000000000003,
            stateColor: "#f29d39",
        },
    },
    {
        name: "nebraska",
        data: {
            rank: 29,
            cdcRecommend: { amount: 20800000 },
            stateSpending: { amountTXT: "$2.6 million" },
            estimatedMarketing: { amount: 62500000 },
            percentCDCSpending: 12.4,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "nevada",
        data: {
            rank: 30,
            cdcRecommend: { amount: 30000000 },
            stateSpending: { amountTXT: "$3.5 million" },
            estimatedMarketing: { amount: 75300000 },
            percentCDCSpending: 11.5,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "new hampshire",
        data: {
            rank: 46,
            cdcRecommend: { amount: 16500000 },
            stateSpending: { amountTXT: "$490,000.00" },
            estimatedMarketing: { amount: 89800000 },
            percentCDCSpending: 3,
            stateColor: "silver",
        },
    },
    {
        name: "new jersey",
        data: {
            rank: 40,
            cdcRecommend: { amount: 103300000 },
            stateSpending: { amountTXT: "$7.1 million" },
            estimatedMarketing: { amount: 169600000 },
            percentCDCSpending: 6.9000000000000004,
            stateColor: "silver",
        },
    },
    {
        name: "new mexico",
        data: {
            rank: 20,
            cdcRecommend: { amount: 22800000 },
            stateSpending: { amountTXT: "$5.7 million" },
            estimatedMarketing: { amount: 34700000 },
            percentCDCSpending: 24.899999999999999,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "new york",
        data: {
            rank: 24,
            cdcRecommend: { amount: 203000000 },
            stateSpending: { amountTXT: "$39.2 million" },
            estimatedMarketing: { amount: 177300000 },
            percentCDCSpending: 19.300000000000001,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "north carolina",
        data: {
            rank: 27,
            cdcRecommend: { amount: 99300000 },
            stateSpending: { amountTXT: "$13.4 million" },
            estimatedMarketing: { amount: 425200000 },
            percentCDCSpending: 13.5,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "north dakota",
        data: {
            rank: 7,
            cdcRecommend: { amount: 9800000 },
            stateSpending: { amountTXT: "$5.7 million" },
            estimatedMarketing: { amount: 36300000 },
            percentCDCSpending: 58,
            stateColor: "#41962a",
        },
    },
    {
        name: "ohio",
        data: {
            rank: 31,
            cdcRecommend: { amount: 132000000 },
            stateSpending: { amountTXT: "$14.8 million" },
            estimatedMarketing: { amount: 429800000 },
            percentCDCSpending: 11.199999999999999,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "oklahoma",
        data: {
            rank: 4,
            cdcRecommend: { amount: 42300000 },
            stateSpending: { amountTXT: "$33 million" },
            estimatedMarketing: { amount: 149500000 },
            percentCDCSpending: 78,
            stateColor: "#41962a",
        },
    },
    {
        name: "oregon",
        data: {
            rank: 1,
            cdcRecommend: { amount: 39300000 },
            stateSpending: { amountTXT: "$53.1 million" },
            estimatedMarketing: { amount: 115000000 },
            percentCDCSpending: 135.09999999999999,
            stateColor: "#00b0f0",
        },
    },
    {
        name: "pennsylvania",
        data: {
            rank: 32,
            cdcRecommend: { amount: 140000000 },
            stateSpending: { amountTXT: "$15.5 million" },
            estimatedMarketing: { amount: 363200000 },
            percentCDCSpending: 11.1,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "rhode island",
        data: {
            rank: 44,
            cdcRecommend: { amount: 12800000 },
            stateSpending: { amountTXT: "$415,452.00" },
            estimatedMarketing: { amount: 24900000 },
            percentCDCSpending: 3.2000000000000002,
            stateColor: "silver",
        },
    },
    {
        name: "south carolina",
        data: {
            rank: 35,
            cdcRecommend: { amount: 51000000 },
            stateSpending: { amountTXT: "$5 million" },
            estimatedMarketing: { amount: 199000000 },
            percentCDCSpending: 9.8000000000000007,
            stateColor: "silver",
        },
    },
    {
        name: "south dakota",
        data: {
            rank: 14,
            cdcRecommend: { amount: 11700000 },
            stateSpending: { amountTXT: "$4.5 million" },
            estimatedMarketing: { amount: 26200000 },
            percentCDCSpending: 38.5,
            stateColor: "#f29d39",
        },
    },
    {
        name: "tennessee",
        data: {
            rank: 47,
            cdcRecommend: { amount: 75600000 },
            stateSpending: { amountTXT: "$2 million" },
            estimatedMarketing: { amount: 289100000 },
            percentCDCSpending: 2.6000000000000001,
            stateColor: "silver",
        },
    },
    {
        name: "texas",
        data: {
            rank: 51,
            cdcRecommend: { amount: 264100000.00000003 },
            stateSpending: { amountTXT: "$3.5 million" },
            estimatedMarketing: { amount: 670900000 },
            percentCDCSpending: 1.3,
            stateColor: "silver",
        },
    },
    {
        name: "utah",
        data: {
            rank: 3,
            cdcRecommend: { amount: 19300000 },
            stateSpending: { amountTXT: "$15.5 million" },
            estimatedMarketing: { amount: 41900000 },
            percentCDCSpending: 80.299999999999997,
            stateColor: "#41962a",
        },
    },
    {
        name: "vermont",
        data: {
            rank: 16,
            cdcRecommend: { amount: 8400000 },
            stateSpending: { amountTXT: "$2.7 million" },
            estimatedMarketing: { amount: 15400000 },
            percentCDCSpending: 32,
            stateColor: "#f29d39",
        },
    },
    {
        name: "virginia",
        data: {
            rank: 28,
            cdcRecommend: { amount: 91600000 },
            stateSpending: { amountTXT: "$11.9 million" },
            estimatedMarketing: { amount: 368400000 },
            percentCDCSpending: 13,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "washington",
        data: {
            rank: 33,
            cdcRecommend: { amount: 63600000 },
            stateSpending: { amountTXT: "$6.6 million" },
            estimatedMarketing: { amount: 87200000 },
            percentCDCSpending: 10.300000000000001,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "washington dc",
        data: {
            rank: 25,
            cdcRecommend: { amount: 10700000 },
            stateSpending: { amountTXT: "$1.9 million" },
            estimatedMarketing: { amount: 4099999.9999999995 },
            percentCDCSpending: 17.800000000000001,
            stateColor: "#fffe7f",
        },
    },
    {
        name: "west virginia",
        data: {
            rank: 50,
            cdcRecommend: { amount: 27400000 },
            stateSpending: { amountTXT: "$445,000.00" },
            estimatedMarketing: { amount: 107900000 },
            percentCDCSpending: 1.6000000000000001,
            stateColor: "silver",
        },
    },
    {
        name: "wisconsin",
        data: {
            rank: 36,
            cdcRecommend: { amount: 57500000 },
            stateSpending: { amountTXT: "$5.3 million" },
            estimatedMarketing: { amount: 170200000 },
            percentCDCSpending: 9.1999999999999993,
            stateColor: "silver",
        },
    },
    {
        name: "wyoming",
        data: {
            rank: 17,
            cdcRecommend: { amount: 8500000 },
            stateSpending: { amountTXT: "$2.5 million" },
            estimatedMarketing: { amount: 21800000 },
            percentCDCSpending: 29,
            stateColor: "#f29d39",
        },
    },
];

$(document).ready(function () {
    if (window.location.pathname == "/what-we-do/us/statereport" || window.location.pathname == "/what-we-do/us/statereport/") {
        $.getJSON("/actions/update-json/default/get-json", function (data) {
            TFK_CHARTS.usmap(JSON.stringify(data));
        });
    }
});
