import $ from "jquery";

const FIXED_CLASS = "position-fixed";

var component_height = 0;
var component_width = 0;
var component_top = 0;
var component_left = 0;
var window_scroll = 0;
var footer_top = 0;

function init() {
    initialize();
    $(window).on("resize", function () {
        initialize();
    });
}

/**
 * Initialize the sticky component
 */
function initialize() {
    if ($("[data-sticky]").length && $(window).width() > 998) {
        component_height = $("[data-sticky]").outerHeight();
        component_width = $("[data-sticky]").outerWidth();
        component_top = $("[data-sticky]").offset().top;
        component_left = $("[data-sticky]").offset().left;
        window_scroll = $(window).scrollTop();
        footer_top = $("footer").offset().top;

        sticky();
        $(window).on("scroll", function () {
            window_scroll = $(window).scrollTop();
            sticky();
        });
    } else if ($("[data-sticky]").length) {
        restore();
    }
}

/**
 * Make the component sticky
 */
function sticky() {
    if (window_scroll > component_top && window_scroll < footer_top - component_height) {
        $("[data-sticky]").addClass(FIXED_CLASS).css({
            width: component_width,
            top: 0,
            left: component_left,
        });
    } else {
        restore();
    }
}

/**
 * Restore the component to its original position
 */
function restore() {
    $("[data-sticky]").removeClass(FIXED_CLASS).css({
        width: "auto",
        top: "auto",
        left: "auto",
    });
}

export default init;
