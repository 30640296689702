import $ from "jquery";
import { createApp } from "vue";

const main = async () => {
    const app = createApp({
        data() {
            return {
                filters: [],
            };
        },
        methods: {
            toggleFilter(val) {
                if (this.filters.includes(val)) {
                    this.filters = this.filters.filter((filter) => filter !== val);
                } else {
                    this.filters.push(val);
                }
                this.filterData();
            },
            filterData() {
                document.querySelectorAll("[data-criteria]").forEach((item) => {
                    if (this.filters.length === 0) {
                        item.classList.remove("d-none");
                    } else {
                        const criteria = JSON.parse(item.getAttribute("data-criteria"));
                        const match = criteria.some((option) => this.filters.includes(option));

                        if (match) {
                            item.classList.remove("d-none");
                        } else {
                            item.classList.add("d-none");
                        }
                    }
                });
            },
            clearFilters() {
                this.filters = [];
                this.filterData();
            },
            showFilters(e) {
                $(e.target).toggleClass("text-danger");
                $(".country-checkboxes").slideToggle();
            },
        },
        compilerOptions: {
            delimiters: ["$[", "]$"],
        },
    });
    const root = app.mount("#plain-packaging-news");
    return root;
};

main()
    .then(() => {
        console.log("Plain Packaging News component loaded.");
    })
    .catch((error) => {
        console.error("Plain  Packaging News component error", error);
    });
