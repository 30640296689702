import $ from "jquery";
import { Modal } from "bootstrap";

import LazyLoad from "vanilla-lazyload";
$(function ($) {
    var myLazyLoad = new LazyLoad({
        elements_selector: ".lazy",
    });

    if ($("#announcement-modal").length) {
        var popUpStatus = Cookie.get("popUpStatusPTK");

        if (popUpStatus != "Shown") {
            Cookie.set("popUpStatusPTK", "Shown", {
                expires: "1D",
                secure: true,
            });

            var announcementModal = new Modal(document.getElementById("announcement-modal"), { backdrop: true, keyboard: true });
            announcementModal.show();
            $("#announcement-modal .close").on("click", function (e) {
                announcementModal.hide();
            });
        }
    }
    /**** Responsive ****/
    var current_width = $(window).width();
    if (current_width > 768) {
        $(".main-nav .dropdown-toggle").click(function () {
            var location = $(this).attr("href");
            window.location.href = location;
            return false;
        });
    } else if (current_width >= 320 && current_width <= 736) {
        $("div.rightSidebar").removeClass("col-xs-4");
    }

    $(window).resize(function () {
        if ($(window).width() >= 320 && $(window).width() <= 736) {
            $("div.rightSidebar").removeClass("col-xs-4");
        } else {
            if (!$("div.rightSidebar").hasClass("col-xs-4")) {
                $("div.rightSidebar").addClass("col-xs-4");
            }
        }
    });

    /**** End Responsive ****/

    if ($(".jumbotron").hasClass("has-image")) {
        var img = $(".jumbotron.has-image img").attr("src");
        $(".jumbotron.has-image").css("background-image", "url('" + img + "')");
    }

    // $('.lang-dropdown').dropdown();
    if (getParam("y") && $("#archive-year").length) {
        $("#archive-year").val(getParam("y"));
    }
    $("#archive-year-form").submit(function (e) {
        e.preventDefault();
        if ($("#archive-year").val() == "all") {
            window.location.href = "/media/press-releases";
        } else {
            window.location.href = "?y=" + $("#archive-year").val();
        }
    });

    /* BEGIN US Resources */
    if ($(".us-resources").length) {
        $(".main-contentblk").attr("id", "us-resources");
        $(".inner-contentcommon").addClass("select-block");
        $(".content-container").addClass("resources");
        $('<div id="results"></div>').insertAfter(".inner-contentcommon");
    }
    $(document).ajaxStop(function () {
        $("#us-resources .paginator a").click(function (e) {
            e.preventDefault();
            //$('#results').html('<div align="center"><div class="loader"></div></div>');
            $("#results").load($(this).attr("href") + " #categoryresults", function () {
                addReturnLink();
            });
            $(".or, #us-states, #keywords, #int-keywords").addClass("hidden");
            $("#categories .clear").removeClass("hidden");
        });
    });

    $(document).ready(function () {
        //recaptcha v2
        // $('#contactus-form').submit(function(e){
        //   var recaptchaVal = $(this).find("#g-recaptcha-response").val();
        //   if(recaptchaVal === ""){
        //     alert('You need to fill the captcha input');
        //     return false;
        //   }else{
        //     return true;
        //   }
        //   return false;
        // });

        $("#toll-form").submit(function (e) {
            e.preventDefault();
            window.location.href = $("#toll-form #toll-state").val();
        });
        $("#fact-form, #int-region-form, #int-issues-form").submit(function (e) {
            e.preventDefault();
            submitCat($(".last-selected").val());
        });

        $(".sbOptions li a").each(function () {
            if (!$(this).text().match("^-")) {
                $(this).addClass("optionParent");
            }
        });

        // $("#int-issues-form .sbOptions li a").each(function(){
        //   if(!$(this).text().match("^-")){
        //     $(this).addClass('optionParent');
        //   }
        // });

        $("#fact-state").change(function () {
            $("#fact-form .fact-sheets").addClass("hidden");
            $("#fact-form .spending").addClass("hidden");
        });

        $("#fact-state, #fact-sheets, #issues, #toll-countries, #toll-issue, #spending").change(function () {
            $(".last-selected").val($(this).attr("id"));
            switch ($(this).val()) {
                case "fact-sheets":
                    $("#fact-form .fact-sheets.hidden").removeClass("hidden");
                    break;
                case "spending":
                    $("#fact-form .spending.hidden").removeClass("hidden");
                    break;
            }
        });

        $("#keyword-form").submit(function (e) {
            e.preventDefault();
            window.location.href = "/search?q=" + $("#keyword-search").val();
            //setSV('keywords',$('#keyword-search').val())

            //$('#results').html('<div align="center"><div class="loader"></div></div>');
            //$('#results').load('/search?q='+encodeURIComponent($('#keyword-search').val())+' #categoryresults', function(){addReturnLink();});
            //$('.or, #us-states, #categories, #int-issues, #int-region').addClass('hidden');
            //$('#keywords .clear').removeClass('hidden');
        });
        $("#int-keyword-form").submit(function (e) {
            e.preventDefault();
            setSV("keywords", $("#keyword-search").val());

            //$('#results').html('<div align="center"><div class="loader"></div></div>');
            $("#results").load("/search/global?q=" + encodeURIComponent($("#keyword-search").val()) + " #categoryresults", function () {
                addReturnLink();
            });
            $(".or, #us-states, #categories, #int-issues, #int-region").addClass("hidden");
            $("#int-keywords .clear").removeClass("hidden");
        });

        if (getParam("s") == "categories") {
            $("#fact-state,#toll-countries,#toll-issue").val(getParam("v"));
            $("#toll-countries,#toll-issue").val(getParam("v"));
            //$('#results').html('<div align="center"><div class="loader"></div></div>');
            $("#results").load(getParam("v") + " #categoryresults", function () {
                addReturnLink();
            });
            $(".or, #us-states, #keywords, #int-keywords").addClass("hidden");
            $("#categories .clear").removeClass("hidden");

            var sel = $('option[value="' + getParam("v") + '"]').parent();
            // $('#page-title span').text(': '+$('option[value="'+getParam('v')+'"]').text().replace('-',''));

            if (sel && sel.attr("id") == "toll-countries") {
                $("#toll-countries").val(getParam("v"));
                $("#int-region").removeClass("hidden");
                $("#int-issues").addClass("hidden");
                $("#int-region .clear").removeClass("hidden");
            }
            if (sel && sel.attr("id") == "toll-issue") {
                $("#toll-issue").val(getParam("v"));
                $("#int-issues").removeClass("hidden");
                $("#int-region").addClass("hidden");
                $("#int-issues .clear").removeClass("hidden");
            }
        }
        if (getParam("s") == "keywords") {
            $("#keyword-search").val(getParam("v"));
            //$('#results').html('<div align="center"><div class="loader"></div></div>');

            if ($("#keyword-search").parent().parent().attr("id") == "int-keyword-form") {
                $("#results").load("/search/global?q=" + encodeURIComponent(getParam("v")) + " #categoryresults", function () {
                    addReturnLink();
                });
            } else {
                //$('#results').load('/search?q='+encodeURIComponent(getParam('v'))+' #categoryresults', function(){addReturnLink();});
                window.location.href = "/search?q=" + encodeURIComponent(getParam("v"));
            }
            $(".or, #us-states, #categories, #int-issues, #int-region").addClass("hidden");
            $("#keywords .clear, #int-keywords .clear").removeClass("hidden");
        }

        if ($("#error404msg").length) {
            $(".main-contentblk").css("padding-top", 0);
            $("#error404msg").parent().parent().parent().addClass("industry-block");
        }

        $("form.search .search-input").focus(function () {
            $(document).keypress(function (e) {
                if (e.which == 13) {
                    $("form.search").submit();
                }
            });
        });

        if ($("body.microsite.netta .inner-contentcommon .industry-block").length) {
            var content = $("body.microsite.netta .inner-contentcommon .industry-block").html();
            $("body.microsite.netta .inner-contentcommon .container .row .col .industry-block").remove();
            $("body.microsite.netta .main-contentblk").prepend(
                '<div class="inner-contentcommon industry-block" style="margin-top:-30px;">' + content + "</div>"
            );
        }
    });
    /* END US Resources */

    /* BEGIN PDF Links */
    $("a[href$='pdf']").each(function () {
        $(this).attr("target", "_blank");
        var $pdflabel = $(this).attr("href");
        $(this).attr("onClick", "_gaq.push(['_trackEvent', 'PDF Files', 'Downloads', '" + $pdflabel + "']);");
    });
    /* END PDF Links */

    $("#signupbox").load("/salsa/box.php #salsabox", function () {
        $("#dialog_link_join_US").addClass("btn-default");
        $("#dialog_link_join_US").parent().removeClass("col-lg-6");
        $("#dialog_link_join_US").parent().addClass("col-lg-8");
        $("#learn-more-us-btn").parent().removeClass("col-lg-6");
        $("#learn-more-us-btn").parent().addClass("col-lg-4");
    });

    $("ul.netta-news.es .month").each(function () {
        switch ($(this).text()) {
            case "January":
                $(this).text("Enero");
                break;
            case "February":
                $(this).text("Febrero");
                break;
            case "March":
                $(this).text("Marzo");
                break;
            case "April":
                $(this).text("Abril");
                break;
            case "May":
                $(this).text("Mayo");
                break;
            case "June":
                $(this).text("Junio");
                break;
            case "July":
                $(this).text("Julio");
                break;
            case "August":
                $(this).text("Agosto");
                break;
            case "September":
                $(this).text("Septiembre");
                break;
            case "October":
                $(this).text("Octubre");
                break;
            case "November":
                $(this).text("Noviembre");
                break;
            case "December":
                $(this).text("Diciembre");
                break;
        }
    });

    /******CUSTOM UPLOAD FILE********/
    var inputs = document.querySelectorAll(".form-control-file");
    Array.prototype.forEach.call(inputs, function (input) {
        input.addEventListener("change", function (e) {
            uploadImageUI(e, input, this);
        });
    });

    /************* 25 YEARS IMAGE UPLOADER *************/
    $(".addMoreImages").click(function (e) {
        e.preventDefault();
        var currenti = $(".uploadGuestImages div.uploadBlock").last().data("i");
        var nexti = Number(currenti) + 1;
        var htmlToCopy = $("#copyBlock_base");
        htmlToCopy = htmlToCopy.html();

        var newHtmlBlock = htmlToCopy.replace(/_copy/g, "");
        newHtmlBlock = newHtmlBlock.replace(/\_base/g, "_" + nexti);
        newHtmlBlock = newHtmlBlock.replace(/\[base]/g, "[" + nexti + "]");
        var newElement = $(
            $.parseHTML(
                '<div id="uploadBlock_' +
                    nexti +
                    '" data-i="' +
                    nexti +
                    '" class="form-group row py-3 uploadBlock  border-bottom">' +
                    newHtmlBlock +
                    "</div>"
            )
        );

        //clean content of current inputs
        newElement.find(".uploadBlock").removeClass("d-none");
        newElement.find(".uploadBlockDeleteImage").on("click", function (e) {
            e.preventDefault();
            deleteImageBlock($(this));
        });

        $(".uploadGuestImages").append(newElement);
        var nextUploadFileInput = document.querySelector("#guestImagesFile_" + nexti);
        $("#guestImagesFile_" + nexti).on("change", function (e) {
            uploadImageUI(e, nextUploadFileInput, this);
        });

        if (nexti >= 9) {
            $(".addMoreImages").remove();
        }
    });

    $("#tfk25years").submit(function (event) {
        enableBtn($(this).find(":submit"), "Loading...", false);
    });

    $(".uploadBlockDeleteImage").click(function (e) {
        e.preventDefault();
        var fileID = $(this).parent().find(".guestImageId").val();
        deleteImageBlock($(this), fileID);
        // console.log($(this).parent().parent().data('i'));
    });

    function addReturnLink() {
        if ($("#page-title").text().indexOf("U.S. Resources") >= 0) {
            $("#results .container").prepend('<a href="/us-resources">Return to main U.S. Resources page</a>');
        }
        if ($("#page-title").text().indexOf("Global Resources") >= 0) {
            $("#results .container").prepend('<a href="/global-resources">Return to main Global Resources page</a>');
        }

        $("#hot-topics, #featured-resources").remove();
    }

    function setSV(s, v) {
        history.pushState({}, "", window.location.pathname + "?" + "s=" + s + "&v=" + encodeURIComponent(v));
    }

    function getParam(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    function submitCat(id) {
        if (id == "spending" || (id == "toll-issue" && $("#" + id).val() == "/what-we-do/global/legal")) {
            window.location.href = $("#" + id).val();
            return 0;
        }

        // $('#page-title span').text(': '+$('#'+id+' option[value="'+$('#'+id).val()+'"]').text().replace('-',''));

        $(".hide-element").addClass("hidden");
        $(".hide-element." + id).removeClass("hidden");
        setSV("categories", $("#" + id).val());

        //$('#results').html('<div align="center"><div class="loader"></div></div>');
        $("#results").load($("#" + id).val() + " #categoryresults", function () {
            addReturnLink();
        });
        $(".or, #us-states, #keywords, #int-keywords").addClass("hidden");
        $("#categories .clear").removeClass("hidden");
        if ($("#" + id).attr("id") == "toll-countries") {
            $("#int-region").removeClass("hidden");
            $("#int-issues").addClass("hidden");
            $("#int-region .clear").removeClass("hidden");
        }
        if ($("#" + id).attr("id") == "toll-issue") {
            $("#int-issues").removeClass("hidden");
            $("#int-region").addClass("hidden");
            $("#int-issues .clear").removeClass("hidden");
        }
    }
});

function countChar(val, lenLimit, currentID) {
    var len = val.value.length;
    if (len > lenLimit) {
        val.value = val.value.substring(0, lenLimit);
    } else {
        $("#" + currentID).text(lenLimit - len);
    }
}

function deleteImageBlock(block, fully = false) {
    var formId = "#tfk25years";
    var i = block.parent().parent().data("i");
    var rowToDelete = "#uploadBlock_" + i;

    if ($("#guestImagesFile_" + i).val() != "" || $("#guestImagesTitle_" + i).val() != "") {
        Swal.fire({
            title: "",
            text: "Are you sure you want to delete this picture?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        }).then((result) => {
            if (result.value) {
                if (fully !== "" && fully !== false) {
                    enableBtn($(formId + " button[type=submit]"), "Deleting...", false);
                    deleteFromEntry(formId, i);
                } else {
                    $(rowToDelete).remove();
                    deleteOverlay();
                }
            }
        });
    } else {
        //complete empty row
        $(rowToDelete).remove();
    }
}

function deleteOverlay() {
    Swal.fire({
        timer: 1200,
        title: "Deleted!",
        text: "Picture has been deleted.",
        icon: "success",
        showConfirmButton: false,
    });
}

function enableBtn(element, text = "Loading...", enable = true) {
    if (enable) {
        element.html("Submit");
        element.removeAttr("disabled");
    } else {
        element.attr("disabled", "disabled");
        element.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ' + text);
    }
}

function deleteFromEntry(formId, i) {
    var rowToDelete = "#uploadBlock_" + i;
    var entryID = $(formId + " input[name=entryId]").val();
    var userToken = $(formId + " input[name*=userToken]").val();
    var ImageId = $(formId + " #guestImagesFileID_" + i).val();

    var data = { entryId: entryID, userToken: userToken, ImageId: ImageId };
    data[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue;

    $.post("/DeleteGuestImage", data, function (response) {
        enableBtn($(formId + " button[type=submit]"));
        if (response == "error") {
            Swal.fire({
                text: "Something went wrong, please try again.",
                icon: "error",
                showConfirmButton: true,
            });
        } else {
            $(rowToDelete).remove();
            deleteOverlay();
        }
    });
}

function uploadImageUI(e, input, $this) {
    var label = input.nextElementSibling,
        labelVal = label.innerHTML;
    var wrapperID = $("#" + input.id)
        .parent()
        .parent()
        .data("i");

    var file = $("#guestImagesFile_" + wrapperID).get(0).files[0];
    var fileName = "";
    if ($this.files && $this.files.length > 1) fileName = ($this.getAttribute("data-multiple-caption") || "").replace("{count}", $this.files.length);
    else fileName = e.target.value.split("\\").pop();

    if (fileName) {
        label.querySelector("strong").innerHTML = fileName;
        if (file) {
            var reader = new FileReader();
            reader.onload = function () {
                $("#uploadBlock_" + wrapperID + " .imagePreview").html('<img src="' + reader.result + '" class="img-fluid">');
            };
            reader.readAsDataURL(file);
        }
    } else {
        label.innerHTML = labelVal;
    }
}

$(".play-profile-video").click(function (e) {
    e.preventDefault();
    $(".modal-profile").toggleClass("d-none");
});

$(".modal-profile").click(function (e) {
    if ($(e.target).hasClass("modal-profile") || $(e.target).hasClass("fa-times") || $(e.target).hasClass("close-modal")) {
        $(".modal-profile").addClass("d-none");
    }
});

$(document).keyup(function (e) {
    if (e.key === "Escape" && !$(".modal-profile").hasClass("d-none")) {
        $(".modal-profile").addClass("d-none");
    }
});

$("body").on("click", "[data-read-more]", function (e) {
    e.preventDefault();
    $(e.currentTarget).parent().find("span").toggleClass("d-none");
});

$("a[href*='#']").on("click", function (e) {
    let href = $(e.currentTarget).attr("href");
    if (href != "#") {
        let target = $(href);
        if ($(target).parent().is(".row-title")) {
            e.preventDefault();
            $(target).parent().trigger("click");
            let top = $(target).parent().offset().top;
            $(window).scrollTop(top);
        }
    }
});

$(function () {
    if ($("#emergingProductsForm.show").length) {
        let modal = document.querySelector("#emergingProductsForm.show");
        const myModal = new Modal(modal, {});
        myModal.show();
    }

    if ($(".emerging-products-header").length) {
        const current_scroll = $(window).scrollTop();
        const header = $(".emerging-products-header");

        if (current_scroll > 100) {
            header.addClass("fixed");
        }

        $(window).scroll(function () {
            const scroll = $(window).scrollTop();
            if (scroll > 100) {
                header.addClass("fixed");
            } else {
                header.removeClass("fixed");
            }
        });
    }
});


$(function () {
    let header_height = $("#header").height();
    $(".homehero").attr("style", "margin-top:" + header_height + "px !important");
});
