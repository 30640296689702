import { Modal, Offcanvas, Tab } from "bootstrap";
import $ from "jquery";
import "@scss/style.scss";

import "./vendor/imgLiquid";
import "./vendor/jquery.matchHeight";
import "./vendor/jquery.selectbox-0.2";
import "./vendor/slick.min.js";

import "./main";
import "./tfk";

import "flag-icons/css/flag-icons.min.css";

import components from "./components"; 
import "./entries"

$(function () {
    components()
});